// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Kn8q6{width:288px;height:300px;display:flex;flex-direction:column;justify-content:flex-end;gap:20px;align-items:center;padding:24px}.D43Kv{display:flex;flex-direction:column;text-align:center}.NvgP2{display:flex;flex-direction:row;align-items:center;gap:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Kn8q6`,
	"textContainer": `D43Kv`,
	"actionButtonsWrapper": `NvgP2`
};
export default ___CSS_LOADER_EXPORT___;
