import { IntegrationSource } from "../../types/api.model";
export function fromDTO(dto) {
  if (dto.source === IntegrationSource.Qbo) {
    return fromIntegrationToQboDTO(dto);
  }
  return dto;
}
function fromIntegrationToQboDTO(dto) {
  return dto.qbo ? {
    id: dto.id,
    companyName: dto.qbo.companyName,
    companyId: dto.id,
    status: dto.status,
    source: dto.source,
    linkUrl: dto.qbo.linkUrl,
    isReady: dto.qbo.isReady,
    settings: dto.qbo.settings,
    qboSettings: dto.qbo.qboSettings,
    accounts: dto.qbo.accounts
  } : null;
}