import { MoneyModel } from "./MoneyModel";
export class ReferralModel {
  static fromDTO(input) {
    return new ReferralModel(input.referralLink, MoneyModel.fromDTO(input.creditsEarned));
  }
  constructor(_referralLink, creditsEarned) {
    this._referralLink = _referralLink;
    this.creditsEarned = creditsEarned;
  }
  creditsEarnedFormatted() {
    return this.creditsEarned.display();
  }
  referralLink() {
    return this._referralLink;
  }
}