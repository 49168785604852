function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import produce from 'immer';
import Decimal from 'decimal.js';
import { defaultBillingDayOfWeek } from "../../constants";
import { generateUniqueId } from 'seagull/utils/generateUniqueId';
import { BillingTriggerType } from "../../types/api.model";
import { DiscountType, IntegratedClassType, RecurrencePeriodTypes, ServiceCostType, ServiceStatusType } from "./model";
import * as selectors from "./selectors";
import { BillingDayOfMonthModel } from "../BillingDayOfMonth";
import { makeManualApprovalMethodDate } from "../approval-method-date";
import { getValidNewOngoingBilling } from "./newOngoingBilling";
import { ServiceIntegrationsModel } from "../ServiceIntegrations";
export function makeChanges(changes) {
  return {
    name: changes.name != null ? changes.name : null,
    description: changes.description != null ? changes.description : null,
    cost: changes.cost != null ? changes.cost : null,
    billing: changes.billing != null ? changes.billing : null
  };
}
export function makePercentDiscount(percent) {
  if (percent < 0 || percent > 100) {
    return null;
  }
  return {
    type: DiscountType.Percent,
    amount: new Decimal(percent)
  };
}
export function makeValueDiscount(value) {
  if (value < 0) {
    return null;
  }
  return {
    type: DiscountType.Value,
    amount: new Decimal(value)
  };
}
export function makeFixedCost(price) {
  let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    pricingType: ServiceCostType.Fixed,
    price: new Decimal(price),
    discount: opts.discount || null,
    priceIncrease: opts.priceIncrease || null
  };
}
export function makeVariableCost(price) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    unitCap,
    unitName,
    discount,
    priceIncrease
  } = options;
  return {
    pricingType: ServiceCostType.Variable,
    price: new Decimal(price),
    unitCap: unitCap != null ? new Decimal(unitCap) : null,
    unitName: unitName || null,
    discount: discount || null,
    priceIncrease: priceIncrease || null
  };
}
export function makeRangeCost(maxPrice) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    minPrice,
    discount,
    priceIncrease
  } = options;
  return {
    pricingType: ServiceCostType.Range,
    maxPrice: new Decimal(maxPrice),
    minPrice: minPrice != null ? new Decimal(minPrice) : null,
    discount: discount || null,
    priceIncrease: priceIncrease || null
  };
}
export function makeOnApprovalBilling() {
  return {
    trigger: BillingTriggerType.OnApproval
  };
}
export function makeOneTimeManualBilling() {
  return {
    trigger: BillingTriggerType.OneTimeManual
  };
}
export function makeOneTimeDeliveryBilling() {
  return {
    trigger: BillingTriggerType.OneTimeDelivery
  };
}
export function makeRepeatableManualBilling(period) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    billingDayOfMonth,
    billingDayOfWeek,
    isBilledUpfront = false,
    maxCharges
  } = options;
  return {
    trigger: BillingTriggerType.RepeatableManual,
    recurrencePeriod: period,
    isBilledUpfront,
    billingDayOfMonth: period === RecurrencePeriodTypes.Monthly ? billingDayOfMonth || BillingDayOfMonthModel.make() : null,
    billingDayOfWeek: selectors.periodRequiresBillingDayOfWeek(period) ? billingDayOfWeek || defaultBillingDayOfWeek : null,
    maxCharges: maxCharges != null ? maxCharges : makeMaxCharges(false)
  };
}
export function makeOngoingBilling(period) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    billingDayOfMonth,
    billingDayOfWeek,
    isPaused = false,
    isProrated = false,
    isBilledUpfront = false,
    billUpfrontState = makeBillUpfrontState(false),
    maxCharges,
    isAwaitingApproval
  } = options;
  return _objectSpread({
    trigger: BillingTriggerType.Ongoing,
    recurrencePeriod: period,
    billingDayOfMonth: period === RecurrencePeriodTypes.Monthly ? billingDayOfMonth || BillingDayOfMonthModel.make() : null,
    billingDayOfWeek: selectors.periodRequiresBillingDayOfWeek(period) ? billingDayOfWeek || defaultBillingDayOfWeek : null,
    maxCharges: maxCharges != null ? maxCharges : makeMaxCharges(false)
  }, isAwaitingApproval ? getValidNewOngoingBilling({
    isPaused,
    isProrated,
    isBilledUpfront,
    billUpfrontState
  }) : {
    isPaused,
    isProrated,
    isBilledUpfront,
    billUpfrontState
  });
}
export function makeAwaitingApprovalStatus() {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    type: ServiceStatusType.AwaitingApproval,
    awaitingApproval: {
      addedOn: options.addedOn || new Date(),
      approvalMethod: options.approvalMethod || makeManualApprovalMethodDate(),
      note: options.note || null
    }
  };
}
export function makeAmendedStatus() {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    type: ServiceStatusType.Amended,
    date: options.date || new Date(),
    amended: {
      amendedOn: options.amendedOn || new Date(),
      changes: makeChanges(options.changes || {}),
      approvalMethod: options.approvalMethod || makeManualApprovalMethodDate(),
      note: options.note || null
    }
  };
}
export function makeApprovedStatus(date) {
  return {
    type: ServiceStatusType.Approved,
    date
  };
}
export function makeCompletedStatus(date, changes) {
  const status = {
    type: ServiceStatusType.Completed,
    date,
    completed: {}
  };
  if (changes) {
    status.completed.changes = makeChanges(changes);
  }
  return status;
}
export function makeTerminatedStatus(date) {
  return {
    type: ServiceStatusType.Terminated,
    date
  };
}
export function makeCanceledStatus() {
  return {
    type: ServiceStatusType.Canceled
  };
}
export function makeService(input) {
  var _input$id, _input$isBillable, _input$serviceTemplat, _input$participation;
  let service = {
    id: (_input$id = input.id) !== null && _input$id !== void 0 ? _input$id : generateUniqueId('service'),
    name: input.name,
    description: input.description,
    status: input.status,
    cost: input.cost,
    billing: input.billing,
    isBillable: (_input$isBillable = input.isBillable) !== null && _input$isBillable !== void 0 ? _input$isBillable : false,
    serviceTemplateId: (_input$serviceTemplat = input.serviceTemplateId) !== null && _input$serviceTemplat !== void 0 ? _input$serviceTemplat : null,
    participation: (_input$participation = input.participation) !== null && _input$participation !== void 0 ? _input$participation : makeMandatoryParticipation(),
    integratedId: input.integratedId,
    integratedClass: input.integratedClass,
    integrations: input.integrations || ServiceIntegrationsModel.make()
  };
  if (!selectors.supportsPriceIncrease(service)) {
    service = produce(service, s => {
      s.cost.priceIncrease = null;
    });
  }
  return service;
}
export function makeMaxCharges(enabled, limit) {
  return {
    enabled,
    limit: enabled ? limit || null : null
  };
}
export function makeBillUpfrontState(skipBillingOnAcceptance) {
  return {
    skipBillingOnAcceptance
  };
}
export function makeMandatoryParticipation() {
  return {
    type: 'mandatory'
  };
}
export function makeOptionalParticipation(includedByDefault) {
  return {
    type: 'optional',
    optional: {
      includedByDefault
    }
  };
}
export function makeIntegratedClassUnset() {
  return {
    type: IntegratedClassType.Unset
  };
}
export function makeIntegratedClassSet(id) {
  return {
    type: IntegratedClassType.Set,
    set: {
      id
    }
  };
}