import { useStore, useStoreActions } from "../../store/store-context";
import { selectors } from "../../store/state-selectors";
import { OAuthProvider } from "../../store/store.model";
import { useQboIntegration } from "../../hooks/useQboIntegration";
import { useLocation } from "../../providers/LocationProvider";
import { wait } from "../../infra/utils";
import { integrationModel } from "../../models";
import { useIsMounted } from 'seagull';
const POLLING_TIMEOUT = 35 * 1000;
const POLLING_INTERVAL = 1000;
export function useQboInVob() {
  const {
    fetchIntegrations,
    api,
    updateUserClientData,
    fetchUserClientData
  } = useStoreActions();
  const authProvider = useStore(selectors.user.authProvider);
  const showQboStep = authProvider !== OAuthProvider.Intuit;
  const {
    connectToQbo
  } = useQboIntegration();
  const location = useLocation();
  const isMounted = useIsMounted();
  const connect = async () => {
    const redirectUrl = "http://".concat(window.location.host, "/home");
    const newIntegration = await connectToQbo(redirectUrl);
    const linkUrl = newIntegration === null || newIntegration === void 0 ? void 0 : newIntegration.linkUrl;
    if (linkUrl) {
      const connectUrl = new URL(linkUrl);
      connectUrl.searchParams.append('host', window.location.host);
      if (location.assign) location.assign(connectUrl.toString());
    } else {
      await fetchIntegrations();
    }
  };
  const setPendingConnect = vobQboState => updateUserClientData({
    vobQboState
  });
  const getState = async () => {
    const {
      vobQboState
    } = await fetchUserClientData();
    return vobQboState;
  };
  const getQboAccounts = async () => {
    await waitForReadyIntegration();
    return api.qbo.getAccounts();
  };
  async function waitForReadyIntegration() {
    const start = Date.now();
    let integrations = await fetchIntegrations();
    while (isMounted() && !((_integrationModel$qbo = integrationModel.qboIntegration(integrations)) !== null && _integrationModel$qbo !== void 0 && _integrationModel$qbo.isReady) && Date.now() - start < POLLING_TIMEOUT) {
      var _integrationModel$qbo;
      await wait(POLLING_INTERVAL);
      integrations = await fetchIntegrations();
    }
    return integrationModel.qboIntegration(integrations);
  }
  return {
    showQboStep,
    connect,
    setPendingConnect,
    getState,
    waitForReadyIntegration,
    getQboAccounts
  };
}