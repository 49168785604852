import Decimal from 'decimal.js';
import { utils } from "../utils";
export class MoneyModel {
  static fromDTO(dto) {
    const amount = new Decimal(dto.amount).div(100).toDP(2);
    return new MoneyModel(amount);
  }
  static getAmount(value) {
    return value instanceof MoneyModel ? value.amount() : value;
  }
  constructor(decimalAmount) {
    this.decimalAmount = decimalAmount;
  }
  toDTO() {
    return {
      amount: utils.dollarsToCents(this.amount())
    };
  }
  amount() {
    return this.decimalAmount;
  }
  display() {
    return utils.formatDollarAmount(this.decimalAmount);
  }
  toNumber() {
    return this.decimalAmount.toNumber();
  }
  mul(quantity) {
    return new MoneyModel(this.decimalAmount.mul(MoneyModel.getAmount(quantity)));
  }
  div(quantity) {
    return new MoneyModel(this.decimalAmount.div(MoneyModel.getAmount(quantity)));
  }
  add(quantity) {
    return new MoneyModel(this.decimalAmount.add(MoneyModel.getAmount(quantity)));
  }
  sub(quantity) {
    return new MoneyModel(this.decimalAmount.sub(MoneyModel.getAmount(quantity)));
  }
  abs() {
    return new MoneyModel(this.decimalAmount.abs());
  }
  neg() {
    return new MoneyModel(this.decimalAmount.neg());
  }
  toFixed(decimalPlaces) {
    return this.decimalAmount.toFixed(decimalPlaces);
  }
  isZero() {
    return this.decimalAmount.isZero();
  }
  isNegative() {
    return this.decimalAmount.isNegative();
  }
}