import { NotificationSettingsEntryType } from "./model";
export function isRecurring(entry) {
  return entry.type === NotificationSettingsEntryType.Recurring;
}
export function recurringMinutes(entry) {
  return isRecurring(entry) ? entry.recurring.minutes : null;
}
export function recurringTimes(entry) {
  return isRecurring(entry) ? entry.recurring.times : null;
}
export const hasRecipients = entry => !!entry.recipientTypes.length;