import range from 'lodash/range';
export function getVendorReminderDaysRange() {
  return {
    minDays: 1,
    maxDays: 30
  };
}
export function getVendorReminderDays() {
  const {
    minDays,
    maxDays
  } = getVendorReminderDaysRange();
  return range(minDays, maxDays + 1);
}
export function getClientReminderDaysRange() {
  return {
    minDays: 1,
    maxDays: 15
  };
}
export function getClientReminderDays() {
  const {
    minDays,
    maxDays
  } = getClientReminderDaysRange();
  return range(minDays, maxDays + 1);
}
export function getClientReminderTimesRange() {
  return {
    minTimes: 1,
    maxTimes: 5
  };
}
export function getClientReminderTimes() {
  const {
    minTimes,
    maxTimes
  } = getClientReminderTimesRange();
  return range(minTimes, maxTimes + 1);
}