// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.etfzu{max-width:1200px;margin:auto;padding:24px 0}.z8MBb{display:flex;justify-content:space-between;margin-bottom:32px}.jeFhO{height:60px}.yIWrR>*{margin-bottom:32px}.Jwyqj{display:flex;align-items:center;gap:16px}.jCazM{height:100%;display:flex;align-items:center;justify-content:center}.Gghur{display:grid;grid-template-columns:auto auto;gap:8px 28px}.BDFBa{display:grid;grid-template-columns:auto;gap:8px;text-align:right}.PwVM5{border:1px solid #eaeaef;border-radius:8px;margin-bottom:32px}.Lr5Ih{border-radius:8px 8px 0 0}.GkLux{border-top:1px solid #eaeaef;border-radius:0 0 8px 8px}.yUBN8{display:flex}.tn86k{white-space:pre-line}.z9uqI{color:#089475;height:16px;width:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"root": `etfzu`,
	"titleSection": `z8MBb`,
	"logo": `jeFhO`,
	"calloutsSection": `yIWrR`,
	"invoiceHeader": `Jwyqj`,
	"error": `jCazM`,
	"invoiceDetails": `Gghur`,
	"vendorDetails": `BDFBa`,
	"table": `PwVM5`,
	"tableHeader": `Lr5Ih`,
	"finalRow": `GkLux`,
	"qboIcon": `yUBN8`,
	"breakOnNewLines": `tn86k`,
	"syncIcon": `z9uqI`
};
export default ___CSS_LOADER_EXPORT___;
