import { integrationModel, qboIntegrationModel } from "../../models";
import { userSelectors } from "./userSelectors";
const integrations = s => s.integrations.data;
const qboIntegration = s => integrationModel.qboIntegration(integrations(s));
const isLinkedToQbo = s => {
  const qbo = qboIntegration(s);
  return !!qbo && qboIntegrationModel.isLinked(qbo);
};
const qboItems = s => s.qboItems.data;
const showQboIntegration = s => isLinkedToQbo(s) && userSelectors.isVendor(s);
const allowToCustomizeQboClasses = s => {
  const qbo = qboIntegration(s);
  return !!qbo && qboIntegrationModel.isLinked(qbo) && qboIntegrationModel.classTrackingAgreementOverride(qbo);
};
const isPendingQboIntegrationSetup = s => {
  var _qboIntegration$accou, _qboIntegration;
  const accountsList = Object.values((_qboIntegration$accou = (_qboIntegration = qboIntegration(s)) === null || _qboIntegration === void 0 ? void 0 : _qboIntegration.accounts) !== null && _qboIntegration$accou !== void 0 ? _qboIntegration$accou : {});
  const isValidAccountsLength = accountsList.length === 3;
  const areAllAccountsSynced = accountsList.every(account => Boolean(account.synced));
  return !isValidAccountsLength || !areAllAccountsSynced;
};
const showInvoiceQboSection = s => !!(showQboIntegration(s) && !isPendingQboIntegrationSetup(s));
const financialCentsIntegration = s => integrationModel.financialCentsIntegration(integrations(s));
const isLinkedToFinancialCents = s => {
  const financialCents = financialCentsIntegration(s);
  return !!financialCents && integrationModel.isLinked(financialCents);
};
const showFinancialCentsIntegration = s => isLinkedToFinancialCents(s) && userSelectors.isVendor(s);
const financialCentsItems = s => s.financialCents.items.data;
export const integrationSelectors = {
  integrations,
  qboIntegration,
  isLinkedToQbo,
  qboItems,
  showQboIntegration,
  isPendingQboIntegrationSetup,
  showInvoiceQboSection,
  allowToCustomizeQboClasses,
  financialCentsIntegration,
  financialCentsItems,
  showFinancialCentsIntegration
};