const _excluded = ["ref"],
  _excluded2 = ["ref", "onChange"],
  _excluded3 = ["ref", "onChange", "value"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/** easily connects an input ref and its error validation */
export const connectControllerToTextField = (props, options) => {
  const _props$field = props.field,
    {
      ref
    } = _props$field,
    rest = _objectWithoutProperties(_props$field, _excluded);
  const {
    showErrors,
    errorMsg
  } = genericConnector(props, options);
  return _objectSpread({
    inputRef: ref,
    error: showErrors,
    helperText: errorMsg
  }, rest);
};
export const connectControllerToKeyboardDatePicker = (props, options) => {
  const {
    value,
    onChange
  } = props.field;
  const {
    showErrors,
    errorMsg
  } = genericConnector(props, options);
  return {
    value,
    onChange,
    error: showErrors,
    helperText: errorMsg
  };
};
export const connectControllerToAutocomplete = (props, options) => {
  const {
    value,
    name,
    ref,
    onBlur,
    onChange
  } = props.field;
  const {
    showErrors,
    errorMsg
  } = genericConnector(props, options);
  return {
    inputValue: value,
    textFieldProps: {
      name,
      error: showErrors,
      helperText: errorMsg,
      inputRef: ref
    },
    onBlur,
    onInputChange: onChange
  };
};
export const connectControllerToDiscountTextField = (props, options) => {
  const _props$field2 = props.field,
    {
      ref,
      onChange: _onChange
    } = _props$field2,
    rest = _objectWithoutProperties(_props$field2, _excluded2);
  const {
    showErrors,
    errorMsg
  } = genericConnector(props, options);
  return _objectSpread({
    inputRef: ref,
    error: showErrors,
    helperText: errorMsg,
    onChange: value => _onChange({
      target: {
        value
      }
    })
  }, rest);
};

/** easily connects a RichTextEditor to a Controller */
export const connectControllerToRichTextEditor = (props, options) => {
  const _props$field3 = props.field,
    {
      ref,
      onChange: _onChange2,
      value
    } = _props$field3,
    rest = _objectWithoutProperties(_props$field3, _excluded3);
  const {
    showErrors,
    errorMsg
  } = genericConnector(props, options);
  return _objectSpread({
    initialValue: value,
    editorRef: ref,
    onChange: v => _onChange2({
      target: {
        value: JSON.stringify(v)
      }
    }),
    error: showErrors,
    helperText: errorMsg
  }, rest);
};
export function genericConnector(_ref) {
  var _fieldState$error;
  let {
    fieldState,
    formState
  } = _ref;
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    noHelperText,
    alwaysShowErrors
  } = options;
  const showErrors = alwaysShowErrors ? !!fieldState.error : getShouldShowFieldError(fieldState, formState);
  const errorMsg = noHelperText ? undefined : showErrors ? (_fieldState$error = fieldState.error) === null || _fieldState$error === void 0 ? void 0 : _fieldState$error.message : undefined;
  return {
    showErrors,
    errorMsg
  };
}
function getShouldShowFieldError(fieldState, formState) {
  return fieldState.error && (fieldState.isTouched || formState.isSubmitted);
}