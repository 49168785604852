import { IntegrationSource, IntegrationStatus } from "../../types/api.model";
export function qboIntegration(integrations) {
  return integrations === null || integrations === void 0 ? void 0 : integrations.find(isQboIntegration);
}
export function isQboIntegration(integration) {
  return integration.source === IntegrationSource.Qbo;
}
export function financialCentsIntegration(integrations) {
  return integrations === null || integrations === void 0 ? void 0 : integrations.find(isFinantialCentsIntegration);
}
export function karbonIntegration(integrations) {
  return integrations === null || integrations === void 0 ? void 0 : integrations.find(isKarbonIntegration);
}
export function isFinantialCentsIntegration(integration) {
  return integration.source === IntegrationSource.FinancialCents;
}
export function isKarbonIntegration(integration) {
  return integration.source === IntegrationSource.Karbon;
}
export function isLinked(integration) {
  return integration.status === IntegrationStatus.Linked;
}