// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jo1jV{width:288px;height:300px;display:flex;flex-direction:column;justify-content:flex-end;gap:20px;align-items:center;padding:24px}.m8TaW{display:flex;flex-direction:column;text-align:center}.Fvz1H{display:flex;flex-direction:row;align-items:center;gap:8px}.XfETR{display:grid;grid-template-columns:repeat(auto-fill, 288px);gap:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `jo1jV`,
	"textContainer": `m8TaW`,
	"actionButtonsWrapper": `Fvz1H`,
	"cardsContainer": `XfETR`
};
export default ___CSS_LOADER_EXPORT___;
