function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import groupBy from 'lodash/groupBy';
import first from 'lodash/first';
import map from 'lodash/map';
import flattenDeep from 'lodash/flattenDeep';
import { isAfter, isBefore, subMonths, addMonths, parse } from 'date-fns';
import { generateUniqueId } from 'seagull/utils/generateUniqueId';
import { daysOfWeek, defaultAgreementTitle, netTermsOptions } from "../../constants";
import { servicesCsvColumnConfig } from "../ImportServices/parseAndValidateServicesCsv";
import { addCsvLineNumbers, createErrorObject, getEmptyStringError, isBoolField, isBoolTrue, parseCsv, validateCsvColumns, validateCsvRow, invalidEmailError } from "../../components/ImportWizard/ImportWizard.utils";
import { RequestAccountantAccess } from "../../models/request-accountant-access";
import { contactModel, timeModel, serviceModel, legalTermsModel, priceIncreaseModel } from "../../models";
import { getUSPhoneNumberValidator, usPhoneNumberError } from 'validators';
import { fromDatestampObject } from "../../models/time";
import Decimal from 'decimal.js';
import { makeAutoApprovalMethodNoticePeriod, makeManualApprovalMethodNoticePeriod } from "../../models/approval-method-notice-period";
import { getAutoApprovalRange, isAutoApprovalValid } from "../../business-logic/autoApprovalLogic";
import { decimalFromStr } from "../../infra/utils";
import { makeIntroVideoLink } from "../../models/intro-video/makers";
import { isUrlValid } from "../../components/AgreementStudio/IntroVideo/IntroVideoModal/IntroVIdeoModal.utils";
import { isValidNewOngoingBilling } from "../../models/service/newOngoingBilling";
import { convertStrToRichText } from 'seagull';
import { getPriceIncreaseDateRange } from "../../business-logic/priceIncreaseLogic";
import { RoundPrices } from "../../models/price-increase";
import { validateContact } from "../../business-logic/validators";
import { BillingDayOfMonthModel } from "../../models";
const defaultCoverLetter = "Dear customer,\nWe've recently started using Anchor Automatic Billing as our billing service provider.\nThis allows us and you to save time and effort, as all invoices are generated automatically.\nSigning this digital agreement will allow your regular payments to happen automatically, and any changes to the agreement or to the payments will require your approval.\n";
export const dayOfMonthOptions = new Array(27).fill(null).map((_, idx) => idx + 1);
export const getInvalidBooleanError = _fieldName => 'invalid entry. Please ensure this column value is set to either "Yes" or "No"';
const paymentTermsError = 'Payment terms can be one of the following: ' + netTermsOptions.map((v, i) => i < netTermsOptions.length - 1 ? v : "or ".concat(v)).join(', ');
export const billingDayError = 'Invalid billing day. For monthly services, the billing day must be a numeric value between <i>1</i> and <i>27</i> or <i>last</i>. For weekly and biweekly services, it should be a day of the week (Monday - Sunday). All other services must not have a billing day set. Please adjust the billing day accordingly';
export const accountantAccessError = "Invalid entry. By filling out the column you request an Accountant access in the proposal, 'please enter <i>mandatory</i> or keep the field empty'";
export const optionalServiceError = 'invalid entry. Please select <i>Included</i> or <i>Not Included</i> to indicate the status of this optional service in the proposal. Leave it blank if the service is not optional';
export const sameAgreementUnmatchingDataError = 'invalid entry. Please ensure this value is set identically in all rows belonging to the same agreement';
export const termsAndConditionsError = 'Invalid terms and conditions file name';
export const billUpfrontLastDayError = 'Invalid entry. It is not possible to bill upfront on the last day of the month. Please change the billing day';
export const singlePackageError = 'Invalid entry. Agreements cannot have a single package. Ensure all services are assigned to a package, with either two or three packages in total';
export const tooManyPackagesError = 'Invalid entry. Agreements cannot have more than three packages. Please reduce the number of packages to three or fewer';
export const packageUnassignedServiceError = 'Invalid entry. All services must be assigned to a package or none at all. Please note, agreements cannot have more than three packages';
export const introVideoUrlError = "Invalid Entry. URL at intro video is invalid. Please enter a valid URL";
function configureValidationFromContactModel(validator) {
  return {
    validate: firstName => {
      const result = validator(firstName);
      return typeof result === 'string' ? {
        valid: false,
        errorType: result
      } : {
        valid: result
      };
    },
    validateError: (headerName, rowNumber, errorType) => createErrorObject(headerName, rowNumber, errorType || '')
  };
}
function makeEffectiveDateParseResult(year, month, day, _now) {
  const date = {
    month: +month,
    day: +day,
    year: +year
  };
  const now = _now || new Date();
  const asDate = timeModel.fromDatestampObject(date);
  if (isBefore(asDate, subMonths(now, 24)) || isAfter(asDate, addMonths(now, 12))) {
    return {
      valid: false,
      error: 'Date should be from 24 months in the past to 12 months in the future'
    };
  }
  return {
    valid: true,
    date
  };
}
const priceIncreaseDateFormat = 'MM-dd-yyyy';
function parsePriceIncreaseStartsOn(autoPriceIncreaseStartsOn, now) {
  return parse(autoPriceIncreaseStartsOn, priceIncreaseDateFormat, now);
}
function parseEffectiveDate(effectiveDate, now) {
  if (!effectiveDate) {
    return {
      valid: true,
      date: null
    };
  }
  const result1 = effectiveDate.match(/^(?:((?:0?(?:1|3|5|7|8))|(?:10|12))[-/]((?:[012]?[0-9])|(?:3[01]))|(?:((?:0?(?:4|6|9))|(?:11))[-/]((?:[012]?[0-9])|(?:30)))|(?:(0?(?:2))[-/]([012]?[0-9])))[-/](\d{4})$/);
  if (result1) {
    const [, month1, day1, month2, day2, month3, day3, year] = result1;
    const month = month1 || month2 || month3;
    const day = day1 || day2 || day3;
    return makeEffectiveDateParseResult(year, month, day, now);
  }
  const result2 = effectiveDate.match(/^(\d{4})[-/](?:((?:[012]?[0-9])|(?:3[01]))[-/]((?:0?(?:1|3|5|7|8))|(?:10|12))|(?:((?:[012]?[0-9])|(?:30))[-/]((?:0?(?:4|6|9))|(?:11)))|(?:([012]?[0-9])[-/](0?(?:2))))$/);
  if (result2) {
    const [, year, day1, month1, day2, month2, day3, month3] = result2;
    const month = month1 || month2 || month3;
    const day = day1 || day2 || day3;
    return makeEffectiveDateParseResult(year, month, day, now);
  }
  return {
    valid: false,
    error: 'Enter a valid date in the following format: mm-dd-yyyy'
  };
}
const getCsvColumnConfig = IMPORT_AGREEMENTS_ADDITIONS => {
  const autoApprovalValidation = {
    required: false,
    shouldMatchSameAgreementServices: true,
    validate(value) {
      const days = parseInt(value);
      const isValidAutoApprovalPeriod = !isNaN(days) && isAutoApprovalValid(days);
      return value === '' || isValidAutoApprovalPeriod;
    },
    validateError: (headerName, rowNumber) => {
      const autoApprovalRange = getAutoApprovalRange();
      return createErrorObject(headerName, rowNumber, "Invalid notice period. Please select a number between ".concat(autoApprovalRange.minDays, " and ").concat(autoApprovalRange.maxDays));
    }
  };
  const config = _objectSpread(_objectSpread({}, servicesCsvColumnConfig), {}, {
    ['email']: _objectSpread(_objectSpread({
      inputName: 'email',
      required: true,
      requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, invalidEmailError)
    }, configureValidationFromContactModel(validateContact.email)), {}, {
      shouldMatchSameAgreementServices: true
    }),
    ['first name']: _objectSpread(_objectSpread({
      inputName: 'firstName',
      required: true,
      requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getEmptyStringError(headerName))
    }, configureValidationFromContactModel(validateContact.firstName)), {}, {
      shouldMatchSameAgreementServices: true
    }),
    ['last name']: _objectSpread(_objectSpread({
      inputName: 'lastName',
      required: true,
      requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getEmptyStringError(headerName))
    }, configureValidationFromContactModel(validateContact.lastName)), {}, {
      shouldMatchSameAgreementServices: true
    }),
    ['company name']: _objectSpread(_objectSpread({
      inputName: 'customerName',
      required: true,
      requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getEmptyStringError(headerName))
    }, configureValidationFromContactModel(validateContact.companyName)), {}, {
      shouldMatchSameAgreementServices: true
    }),
    ['agreement title']: {
      inputName: 'agreementTitle',
      shouldMatchSameAgreementServices: true
    },
    ['payment terms']: {
      inputName: 'paymentTerms',
      required: true,
      requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, paymentTermsError),
      validate: paymentTerms => {
        const isUponReceipt = (paymentTerms === null || paymentTerms === void 0 ? void 0 : paymentTerms.toLowerCase()) === 'upon receipt';
        const containsAnExpectedNumber = netTermsOptions.includes(parseInt(paymentTerms));
        return isUponReceipt || containsAnExpectedNumber;
      },
      validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, paymentTermsError),
      shouldMatchSameAgreementServices: true
    },
    ['require payment method']: {
      inputName: 'requirePaymentMethod',
      required: true,
      requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
      validate: isBoolField,
      validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
      shouldMatchSameAgreementServices: true
    },
    ['email body']: {
      inputName: 'emailBody',
      shouldMatchSameAgreementServices: true
    },
    ['phone number']: {
      inputName: 'buyerPhone',
      validate: buyerPhone => !buyerPhone || getUSPhoneNumberValidator()('+1' + buyerPhone) === true,
      validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, usPhoneNumberError),
      shouldMatchSameAgreementServices: true
    },
    ['billed upfront']: {
      inputName: 'billedUpfront',
      required: true,
      requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
      validate: isBoolField,
      validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
      shouldMatchSameAgreementServices: false
    },
    ['billing day']: {
      inputName: 'billingDay',
      required: false,
      validate: (billingDay, _ref) => {
        let {
          rowObject
        } = _ref;
        const isLast = billingDay.toLowerCase() === 'last';
        const containsAnExpectedNumber = dayOfMonthOptions.includes(parseInt(billingDay));
        const isDayOfWeek = daysOfWeek.map(day => day.toLowerCase()).includes(billingDay.toLowerCase());
        return rowObject.billingOccurrence.toLowerCase() === 'monthly' && (isLast || containsAnExpectedNumber) || ['weekly', 'biweekly'].includes(rowObject.billingOccurrence.toLowerCase()) && isDayOfWeek || billingDay === '';
      },
      validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, billingDayError),
      shouldMatchSameAgreementServices: false
    },
    ['effective date']: {
      inputName: 'effectiveDate',
      validate: (effectiveDate, _ref2) => {
        let {
          now
        } = _ref2;
        const parsed = parseEffectiveDate(effectiveDate, now);
        return parsed.valid ? true : {
          valid: false,
          errorType: parsed.error
        };
      },
      validateError: (headerName, rowNumber, errorType) => createErrorObject(headerName, rowNumber, errorType || ''),
      shouldMatchSameAgreementServices: true
    },
    ['accountant access']: {
      inputName: 'accountantAccess',
      required: true,
      requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
      validate: accountantAccess => isBoolField(accountantAccess),
      validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
      shouldMatchSameAgreementServices: true
    },
    ['mark this service as optional']: {
      inputName: 'optionalService',
      required: false,
      validate: optionalService => {
        return !optionalService || ['included', 'not included'].includes(optionalService.toLowerCase());
      },
      validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, optionalServiceError),
      shouldMatchSameAgreementServices: false
    },
    ['terms and conditions']: {
      inputName: 'termsAndConditions',
      required: false,
      validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, termsAndConditionsError),
      shouldMatchSameAgreementServices: true
    },
    ['issue invoice on acceptance']: {
      inputName: 'issueInvoiceOnAcceptance',
      validate: issueInvoiceOnAcceptance => isBoolField(issueInvoiceOnAcceptance),
      validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName))
    },
    ['pause automatic billing']: {
      inputName: 'startPaused',
      validate: startPaused => isBoolField(startPaused),
      validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName))
    },
    ['agreement note']: {
      inputName: 'extraClause',
      shouldMatchSameAgreementServices: true
    },
    ['amendments auto approval']: _objectSpread({
      inputName: 'amendmentsAutoApproval'
    }, autoApprovalValidation),
    ['packages']: {
      inputName: 'packages',
      required: false
    }
  });
  if (IMPORT_AGREEMENTS_ADDITIONS) {
    config['intro video'] = {
      inputName: 'introVideoUrl',
      required: false,
      shouldMatchSameAgreementServices: true,
      validate: introVideoUrl => {
        if (introVideoUrl === '') {
          return true;
        }
        return isUrlValid(introVideoUrl);
      },
      validateError: (headerName, rowNumber) => {
        return createErrorObject(headerName, rowNumber, introVideoUrlError);
      }
    };
    const minAutoPriceIncreaseRate = 0.01;
    const maxAutoPriceIncreaseRate = 100;
    config['automatic yearly price increase rate'] = {
      inputName: 'priceIncreaseRate',
      validate(priceIncreaseRate) {
        if (!priceIncreaseRate) {
          return true;
        }
        const asNumber = +priceIncreaseRate;
        return !isNaN(asNumber) && asNumber >= minAutoPriceIncreaseRate && asNumber <= maxAutoPriceIncreaseRate;
      },
      validateError(headerName, rowNumber) {
        return createErrorObject(headerName, rowNumber, "Invalid price increase rate. Please enter a number between ".concat(minAutoPriceIncreaseRate, " and ").concat(maxAutoPriceIncreaseRate));
      }
    };
    config['automatic yearly price increase starts on'] = {
      inputName: 'priceIncreaseStartsOn',
      validate(priceIncreaseStartsOn, _ref3) {
        let {
          now = new Date(),
          rowObject: {
            effectiveDate
          }
        } = _ref3;
        if (!priceIncreaseStartsOn) {
          return true;
        }
        const parsed = parsePriceIncreaseStartsOn(priceIncreaseStartsOn, now);
        if (isNaN(parsed.getTime())) {
          return {
            valid: false,
            errorType: "Invalid date format. Please enter a date in the format ".concat(priceIncreaseDateFormat)
          };
        }
        const parsedEffectiveDate = parseEffectiveDate(effectiveDate, now);
        const realEffectiveDate = parsedEffectiveDate.valid && parsedEffectiveDate.date ? fromDatestampObject(parsedEffectiveDate.date) : null;
        const {
          minDate,
          maxDate
        } = getPriceIncreaseDateRange(now, realEffectiveDate);
        if (isBefore(parsed, minDate) || isAfter(parsed, maxDate)) {
          return {
            valid: false,
            errorType: "Date should be from ".concat(timeModel.formatDate(minDate), " to ").concat(timeModel.formatDate(maxDate))
          };
        }
        return true;
      },
      validateError(headerName, rowNumber, errorType) {
        return createErrorObject(headerName, rowNumber, errorType || '');
      }
    };
    config['out of scope charges auto approval'] = _objectSpread({
      inputName: 'invoicesAutoApproval'
    }, autoApprovalValidation);
  }
  return config;
};
function getIsPaused(rowObject) {
  return isBoolTrue(rowObject.startPaused || '');
}
function getSkipBillingOnAcceptance(rowObject) {
  return !isBoolTrue(rowObject.issueInvoiceOnAcceptance || '');
}
function getIsRecurring(rowObject) {
  var _rowObject$billingOcc;
  return ((_rowObject$billingOcc = rowObject.billingOccurrence) === null || _rowObject$billingOcc === void 0 ? void 0 : _rowObject$billingOcc.toLowerCase()) !== 'one time';
}
function runValidation(data, availableLegalTerms, now, csvColumnConfig) {
  const errorContext = {
    phase: 'validation'
  };
  if (!data.length) throw new Error('No data');
  validateCsvColumns(data, csvColumnConfig);
  const withLineNumbers = addCsvLineNumbers(data);
  const groupedServicesByAgreement = groupBy(withLineNumbers, _ref4 => {
    let {
      email,
      customerName
    } = _ref4;
    return "".concat(email, ":").concat(customerName);
  });
  const errors = map(groupedServicesByAgreement, serviceRows => {
    const [packageNames, hasServicesWithoutPackageName] = serviceRows.reduce((acc, rowObject) => {
      const emptyPackageName = rowObject.packages === '';
      return [!emptyPackageName ? acc[0].add(rowObject.packages) : acc[0], acc[1] || emptyPackageName];
    }, [new Set([]), false]);
    if (packageNames.size === 1) {
      return createErrorObject('packages', serviceRows[0].lineNumber, singlePackageError);
    }
    if (packageNames.size > 3) {
      return createErrorObject('packages', serviceRows[0].lineNumber, tooManyPackagesError);
    }
    if (packageNames.size > 0 && hasServicesWithoutPackageName) {
      return createErrorObject('packages', serviceRows[0].lineNumber, packageUnassignedServiceError);
    }
    return serviceRows.map(rowObject => {
      errorContext['service'] = rowObject.serviceName;
      errorContext['lineNumber'] = rowObject.lineNumber;
      try {
        return validateCsvRow(rowObject, csvColumnConfig, (columnConfig, columnName) => {
          const {
            inputName,
            shouldMatchSameAgreementServices
          } = columnConfig;
          const {
            lineNumber,
            billedUpfront,
            automaticBilling,
            issueInvoiceOnAcceptance,
            billingDay
          } = rowObject;
          const value = rowObject[inputName];
          const isRecurring = getIsRecurring(rowObject);
          const isAutomaticBilling = isBoolTrue(automaticBilling);
          const isOngoing = isRecurring && isAutomaticBilling;
          const isBilledUpfront = isBoolTrue(billedUpfront);
          const isPaused = getIsPaused(rowObject);
          const isValidSkipBillingOnAcceptance = isBoolField(issueInvoiceOnAcceptance || '');
          const skipBillingOnAcceptance = getSkipBillingOnAcceptance(rowObject);
          if (shouldMatchSameAgreementServices && value !== serviceRows[0][inputName]) {
            return createErrorObject(columnName, lineNumber, sameAgreementUnmatchingDataError);
          }
          if (inputName === 'termsAndConditions' && typeof value === 'string' && value !== '' && !availableLegalTerms.some(doc => legalTermsModel.name(doc).toLowerCase() === value.toLowerCase() || legalTermsModel.name(doc).toLowerCase() === value.toLowerCase() + '.pdf')) {
            return createErrorObject(columnName, lineNumber, termsAndConditionsError);
          }
          if (inputName === 'billingOccurrence' && typeof value === 'string' && value.toLowerCase() === 'monthly' && billingDay === '') {
            return createErrorObject(columnName, lineNumber, billingDayError);
          }
          if (inputName === 'billingOccurrence' && typeof value === 'string' && (value.toLowerCase() === 'weekly' || value.toLowerCase() === 'biweekly') && billingDay === '') {
            return createErrorObject(columnName, lineNumber, billingDayError);
          }
          if (inputName === 'billingDay' && isBilledUpfront && typeof value === 'string' && value.toLowerCase() === 'last') {
            return createErrorObject(columnName, lineNumber, billUpfrontLastDayError);
          }
          if (inputName === 'issueInvoiceOnAcceptance' && isValidSkipBillingOnAcceptance && isOngoing && !isValidNewOngoingBilling({
            isBilledUpfront,
            isPaused,
            isProrated: false,
            billUpfrontState: serviceModel.makeBillUpfrontState(skipBillingOnAcceptance)
          })) {
            return createErrorObject(columnName, lineNumber, 'You can issue an invoice on acceptance only for automatic, recurring, unpaused, billed-upfront services');
          }
        }, now);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (exn) {
        console.error({
          error: '' + exn,
          errorContext
        });
        if ((exn === null || exn === void 0 ? void 0 : exn.message) != null) {
          errorContext['exn'] = exn.message;
          exn.message = JSON.stringify(errorContext);
        }
        throw exn;
      }
    });
  });
  return flattenDeep(errors);
}
const createServiceFromCsvRow = (rowObject, now) => {
  const {
    serviceName,
    serviceDescription,
    optionalService
  } = rowObject;
  return {
    id: generateUniqueId('service'),
    name: serviceName,
    description: convertStrToRichText(serviceDescription),
    billing: serviceModel.billingToDTO(makeServiceBilling(rowObject)),
    cost: serviceModel.costToDTO(makeServiceCost(rowObject, now)),
    participation: makeServiceParticipation(optionalService),
    isBillable: false,
    serviceTemplateId: null,
    status: serviceModel.statusToDTO(serviceModel.makeAwaitingApprovalStatus())
  };
};
function makeServiceParticipation(optionalService) {
  const normalizedOptionalService = optionalService === null || optionalService === void 0 ? void 0 : optionalService.toLowerCase().trim();
  if (['included', 'not included'].includes(normalizedOptionalService)) {
    const includedByDefault = normalizedOptionalService === 'included';
    return {
      type: 'optional',
      optional: {
        includedByDefault
      }
    };
  } else {
    return {
      type: 'mandatory'
    };
  }
}
function makeServiceCost(rowObject, now) {
  const {
    pricing,
    rate,
    discountType,
    unitName,
    unitCap,
    priceIncreaseRate: autoPriceIncreaseRate,
    priceIncreaseStartsOn: autoPriceIncreaseStartsOn
  } = rowObject;
  const discount = decimalFromStr(rowObject.discount);
  const priceIncrease = autoPriceIncreaseRate ? priceIncreaseModel.makePriceIncrease(RoundPrices.None, new Decimal(autoPriceIncreaseRate), autoPriceIncreaseStartsOn ? parsePriceIncreaseStartsOn(autoPriceIncreaseStartsOn, now) : null) : null;
  const discountObject = discount != null && discount ? (discountType === null || discountType === void 0 ? void 0 : discountType.toLowerCase()) === 'flat' ? serviceModel.makeValueDiscount(discount.toNumber()) : serviceModel.makePercentDiscount(discount.toNumber()) : null;
  switch (pricing === null || pricing === void 0 ? void 0 : pricing.toLowerCase()) {
    case 'fixed':
      {
        return serviceModel.makeFixedCost(decimalFromStr(rate), {
          discount: discountObject,
          priceIncrease
        });
      }
    case 'hourly':
    case 'quantity':
      return serviceModel.makeVariableCost(decimalFromStr(rate), {
        unitName,
        unitCap: decimalFromStr(unitCap) || new Decimal(0),
        discount: discountObject,
        priceIncrease
      });
    case 'range':
      {
        const [first, second] = rate.split('-').map(p => p.trim());
        const hasMin = Boolean(second); // when there is only one number > it is the maximum price
        return serviceModel.makeRangeCost(decimalFromStr(second || first), {
          minPrice: hasMin ? decimalFromStr(first) : null,
          discount: discountObject,
          priceIncrease
        });
      }
    default:
      throw new Error('bad service pricing configuration');
  }
}
function makeServiceBilling(rowObject) {
  const {
    billedUpfront,
    automaticBilling,
    billingOccurrence,
    billingDay
  } = rowObject;
  const isRecurring = getIsRecurring(rowObject);
  const isAutomaticBilling = isBoolTrue(automaticBilling);
  const isMonthly = 'monthly' === billingOccurrence.toLowerCase();
  const isWeekly = ['weekly', 'biweekly'].includes(billingOccurrence.toLowerCase());
  const isBilledUpfront = isBoolTrue(billedUpfront);
  const billingDayOfMonth = isMonthly ? makeBillingDayOfMonth(billingDay) : null;
  const billingDayOfWeek = isWeekly ? billingDay : null;
  const recurrencePeriod = billingOccurrence.toLowerCase();
  const isPaused = getIsPaused(rowObject);
  const skipBillingOnAcceptance = getSkipBillingOnAcceptance(rowObject);
  return isAutomaticBilling && !isRecurring ? serviceModel.makeOnApprovalBilling() : !isAutomaticBilling && !isRecurring ? serviceModel.makeOneTimeManualBilling() : !isAutomaticBilling && isRecurring ? serviceModel.makeRepeatableManualBilling(recurrencePeriod, {
    billingDayOfMonth,
    billingDayOfWeek,
    isBilledUpfront,
    maxCharges: serviceModel.makeMaxCharges(false)
  }) : serviceModel.makeOngoingBilling(recurrencePeriod, {
    isAwaitingApproval: true,
    billingDayOfMonth,
    billingDayOfWeek,
    maxCharges: serviceModel.makeMaxCharges(false),
    isProrated: false,
    isPaused,
    isBilledUpfront,
    billUpfrontState: serviceModel.makeBillUpfrontState(skipBillingOnAcceptance)
  });
}
function makeBillingDayOfMonth(billingDay) {
  return BillingDayOfMonthModel.make(billingDay.toLowerCase() === 'last' ? 'last' : parseInt(billingDay));
}
const createDraft = function createDraft(rows, defaultDraftsValues, availableLegalTerms) {
  let now = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : new Date();
  const errorContext = {};
  try {
    const firstRow = first(rows);
    if (firstRow == null) throw new Error('empty CSV');
    const {
      email,
      firstName,
      lastName,
      buyerPhone,
      customerName,
      agreementTitle,
      paymentTerms,
      requirePaymentMethod,
      emailBody,
      effectiveDate,
      accountantAccess,
      termsAndConditions,
      amendmentsAutoApproval,
      extraClause,
      invoicesAutoApproval
    } = firstRow;
    errorContext['email'] = email;
    errorContext['customerName'] = customerName;
    errorContext['agreementTitle'] = agreementTitle;
    const isUponReceipt = (paymentTerms === null || paymentTerms === void 0 ? void 0 : paymentTerms.toLowerCase()) === 'upon receipt';
    const netTerms = isUponReceipt ? 0 : parseInt(paymentTerms);
    const packages = groupBy(rows, _ref5 => {
      let {
        packages
      } = _ref5;
      return packages;
    });
    const packageNames = Object.keys(packages);
    const serviceBundles = packageNames.reduce((acc, packageName) => {
      const packageRows = packages[packageName];
      const services = packageRows.map(r => {
        errorContext['phase'] = 'create-service';
        errorContext['service'] = r.serviceName;
        return createServiceFromCsvRow(r, now);
      });
      acc.bundles.push({
        id: generateUniqueId('bundle'),
        name: packageName,
        services
      });
      return acc;
    }, {
      bundles: []
    });
    errorContext['phase'] = undefined;
    errorContext['service'] = undefined;
    const parsedEffectiveDate = parseEffectiveDate(effectiveDate, now);
    const legalTermsDoc = availableLegalTerms.find(doc => legalTermsModel.name(doc).toLowerCase() === termsAndConditions.toLowerCase() || legalTermsModel.name(doc).toLowerCase() === termsAndConditions.toLowerCase() + '.pdf');
    return {
      draft: {
        id: generateUniqueId('draft'),
        coverLetter: defaultCoverLetter,
        extraClause: convertStrToRichText(extraClause || ''),
        requirePaymentMethod: isBoolTrue(requirePaymentMethod),
        allowManualPayments: false,
        isExistingCustomer: true,
        customNotificationText: (emailBody === null || emailBody === void 0 ? void 0 : emailBody.length) > 0 ? emailBody : '',
        title: agreementTitle.length > 0 ? agreementTitle : defaultAgreementTitle,
        lastModifiedOn: Date.now(),
        netTerms,
        effectiveDate: parsedEffectiveDate.valid ? parsedEffectiveDate.date : null,
        legalTerms: legalTermsDoc && legalTermsModel.toDraftLegalTerms(legalTermsDoc, false) || defaultDraftsValues.legalTerms || null,
        feeControls: {
          coverCreditCardFees: false,
          coverDirectDebitFees: false
        },
        creationStrategy: null,
        clientContactId: null,
        isSample: false,
        amendmentsApprovalMethod: amendmentsAutoApproval ? makeAutoApprovalMethodNoticePeriod(parseInt(amendmentsAutoApproval)) : makeManualApprovalMethodNoticePeriod(),
        invoicesApprovalMethod: invoicesAutoApproval ? makeAutoApprovalMethodNoticePeriod(parseInt(invoicesAutoApproval)) : makeManualApprovalMethodNoticePeriod(),
        requestAccountantAccess: isBoolTrue(accountantAccess) ? RequestAccountantAccess.Mandatory : RequestAccountantAccess.None,
        serviceBundles: packageNames.length > 1 ? serviceBundles : undefined,
        services: packageNames.length > 1 ? [] : serviceBundles.bundles[0].services,
        reviewers: [],
        introVideo: rows[0].introVideoUrl ? makeIntroVideoLink(rows[0].introVideoUrl) : null
      },
      contact: contactModel.makeNew({
        email: email,
        firstName: firstName,
        lastName: lastName,
        phone: buyerPhone ? '+1' + buyerPhone : null,
        companyName: customerName
      })
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (exn) {
    console.error({
      error: '' + exn,
      errorContext
    });
    if ((exn === null || exn === void 0 ? void 0 : exn.message) != null) {
      errorContext['exn'] = exn.message;
      exn.message = JSON.stringify(errorContext);
    }
    throw exn;
  }
};
const createDrafts = (parsedCsv, defaultDraftsValues, availableLegalTerms, now) => {
  const groupedServicesByAgreement = groupBy(parsedCsv, _ref6 => {
    let {
      email,
      customerName
    } = _ref6;
    return "".concat(email, ":").concat(customerName);
  });
  return map(groupedServicesByAgreement, serviceRows => createDraft(serviceRows, defaultDraftsValues, availableLegalTerms, now));
};
function tryToParseError(error, or) {
  try {
    return JSON.parse(error);
  } catch (e) {
    return or;
  }
}
export const parseAndValidateAgreementCsv = async _ref7 => {
  let {
    data,
    defaultDraftsValues,
    availableLegalTerms,
    now,
    IMPORT_AGREEMENTS_ADDITIONS
  } = _ref7;
  try {
    const csvColumnConfig = getCsvColumnConfig(IMPORT_AGREEMENTS_ADDITIONS);
    const parsedData = await parseCsv(data, csvColumnConfig);
    const errors = runValidation(parsedData, availableLegalTerms, now, csvColumnConfig);
    if (!errors.length) {
      const items = createDrafts(parsedData, defaultDraftsValues, availableLegalTerms, now);
      return {
        items,
        errors: []
      };
    } else {
      return {
        items: [],
        errors
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (exn) {
    var _tryToParseError;
    return {
      items: [],
      errors: [{
        message: '' + exn,
        rowNumber: ((_tryToParseError = tryToParseError(exn === null || exn === void 0 ? void 0 : exn.message, 0)) === null || _tryToParseError === void 0 ? void 0 : _tryToParseError.lineNumber) || 0
      }]
    };
  }
};