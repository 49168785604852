import { isAfter, startOfDay } from 'date-fns';
import * as timeModel from "../time";
import { UserRole } from "../../store/store.model";
import { relationshipModel } from "./..";

/** Returns true if the relationship's effectiveDate is in the future. Meaning it's not in-effect yet. */
export function isFutureRelationship(_ref, now) {
  let {
    effectiveDate
  } = _ref;
  return !!effectiveDate && isAfter(effectiveDate instanceof Date ? effectiveDate : timeModel.fromDatestampObject(effectiveDate), startOfDay(now));
}
export const hasClientViewPermission = role => role === UserRole.Owner || role === UserRole.Member;
export const hasUserSigned = (relationship, userId) => {
  if (relationshipModel.isSignatory(relationship, userId)) {
    return relationshipModel.hasSignatorySigned(relationship, userId);
  }
  if (relationshipModel.isBuyer(relationship, userId)) {
    return relationship.approvalRecord !== null;
  }
  return false;
};