// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dzQCa{display:flex;flex-direction:row;gap:8px}.RASpV{display:flex;flex-direction:row;gap:8px}.CCvB4{display:flex;flex-direction:column;gap:12px}.pJeCy{padding:24px}.pJeCy .x1uC3{padding-bottom:24px}.HUhch{width:168px}.mxNwr{display:flex;flex-direction:row;align-items:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": `dzQCa`,
	"pageSubHeader": `RASpV`,
	"pageContainer": `CCvB4`,
	"section": `pJeCy`,
	"header": `x1uC3`,
	"button": `HUhch`,
	"customTxnNumbersWarning": `mxNwr`
};
export default ___CSS_LOADER_EXPORT___;
