// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AAFK6{margin-bottom:8px;display:flex;align-items:flex-end;justify-content:center;gap:4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtext": `AAFK6`
};
export default ___CSS_LOADER_EXPORT___;
