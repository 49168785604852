import { makeDefaultBranding } from "../store.utils";
import { UserRole, KycCompletionStatus, PayoutBankAccountType } from "../store.model";

/** Returns the user's current business. */
const business = s => {
  var _s$userInfo;
  return (_s$userInfo = s.userInfo) === null || _s$userInfo === void 0 ? void 0 : _s$userInfo.worksAt;
};
const videos = s => s.videos;

/** Returns whether the user is a vendor. */
const isVendor = s => {
  var _business;
  return !!((_business = business(s)) !== null && _business !== void 0 && (_business = _business.features) !== null && _business !== void 0 && _business.accountsReceivable);
};

/** Returns whether the user is a client. */
const isClient = s => {
  var _business2;
  return !!((_business2 = business(s)) !== null && _business2 !== void 0 && (_business2 = _business2.features) !== null && _business2 !== void 0 && _business2.accountsPayable);
};

/** Returns the role of the user in his current business. */
const role = s => {
  var _business3;
  return (_business3 = business(s)) === null || _business3 === void 0 ? void 0 : _business3.role;
};
const hasBusiness = s => !!business(s);
const businessId = s => {
  var _business4;
  return (_business4 = business(s)) === null || _business4 === void 0 ? void 0 : _business4.id;
};
const businessName = s => {
  var _business5;
  return (_business5 = business(s)) === null || _business5 === void 0 ? void 0 : _business5.name;
};
const workspaces = s => {
  var _s$userInfo2;
  return (_s$userInfo2 = s.userInfo) === null || _s$userInfo2 === void 0 ? void 0 : _s$userInfo2.workspaces;
};
const businessesWithAccountantAccess = s => {
  const spaces = workspaces(s);
  const val = spaces ? spaces.filter(ws => ws.role === UserRole.Accountant).map(ws => ws.id) : [];
  return new Set(val);
};
const businessSettings = s => {
  var _business6;
  return (_business6 = business(s)) === null || _business6 === void 0 ? void 0 : _business6.settings;
};
const introVideo = s => {
  var _businessSettings;
  return (_businessSettings = businessSettings(s)) === null || _businessSettings === void 0 ? void 0 : _businessSettings.introVideo;
};
const id = s => {
  var _s$userInfo3;
  return (_s$userInfo3 = s.userInfo) === null || _s$userInfo3 === void 0 ? void 0 : _s$userInfo3.id;
};
const email = s => {
  var _s$userInfo4;
  return (_s$userInfo4 = s.userInfo) === null || _s$userInfo4 === void 0 ? void 0 : _s$userInfo4.email;
};
const firstName = s => {
  var _s$userInfo5;
  return (_s$userInfo5 = s.userInfo) === null || _s$userInfo5 === void 0 ? void 0 : _s$userInfo5.firstName;
};
const businessClientData = (s, businessID) => {
  const bid = businessID || businessId(s);
  return bid ? s.businessClientData[bid] : undefined;
};
const isVendorInRelationship = relationship => s => {
  const bid = businessId(s);
  return !!bid && relationship.vendor.id === bid;
};

/**
 * Returns true if the user has a role of 'member' in his current business.
 * Returns false if we don't have worksAt data.
 */
const isMemberUser = state => {
  const userRole = role(state);
  return !!userRole && userRole === UserRole.Member;
};
const isAccountant = s => {
  const userRole = role(s);
  return !!userRole && userRole === UserRole.Accountant;
};
const isAnchorAdmin = s => {
  const userRole = role(s);
  return !!userRole && userRole === UserRole.AnchorAdmin;
};
const authProvider = s => {
  var _s$userInfo6;
  return (_s$userInfo6 = s.userInfo) === null || _s$userInfo6 === void 0 ? void 0 : _s$userInfo6.oAuthProvider;
};
export const userSelectors = {
  isVendor,
  isClient,
  role,
  business,
  videos,
  workspaces,
  businessesWithAccountantAccess,
  businessSettings,
  introVideo,
  hasBusiness,
  businessId,
  businessName,
  id,
  email,
  firstName,
  lastName: s => {
    var _s$userInfo7;
    return (_s$userInfo7 = s.userInfo) === null || _s$userInfo7 === void 0 ? void 0 : _s$userInfo7.lastName;
  },
  businessClientData,
  modifiedAServiceTemplate: s => {
    var _businessClientData;
    return !!((_businessClientData = businessClientData(s)) !== null && _businessClientData !== void 0 && _businessClientData.modifiedAServiceTemplate);
  },
  isVendorInRelationship,
  isMemberUser,
  isAccountant,
  isReviewer(s) {
    const userRole = role(s);
    return !!userRole && userRole === UserRole.Reviewer;
  },
  isAnchorAdmin,
  authProvider,
  kycCompleted(s) {
    const worksAt = business(s);
    return worksAt ? worksAt.kycCompletionStatus === KycCompletionStatus.Completed : false;
  },
  hasLinkedBankAccount(s) {
    const worksAt = business(s);
    return worksAt ? worksAt.hasLinkedBankAccount : false;
  },
  payoutBankAccount(s) {
    const worksAt = business(s);
    return worksAt ? worksAt.payoutBankAccount : null;
  },
  hasBankAccountConnected(s) {
    var _userSelectors$payout;
    return ((_userSelectors$payout = userSelectors.payoutBankAccount(s)) === null || _userSelectors$payout === void 0 ? void 0 : _userSelectors$payout.type) === PayoutBankAccountType.Connected;
  },
  hasDisabledPayments(s) {
    const worksAt = business(s);
    return worksAt ? worksAt.disablePayments : false;
  },
  hasUserInfo(s) {
    return s.userInfo != null;
  },
  branding: s => {
    var _business7;
    return ((_business7 = business(s)) === null || _business7 === void 0 ? void 0 : _business7.branding) || makeDefaultBranding();
  },
  businessLogo: s => {
    var _business8;
    return ((_business8 = business(s)) === null || _business8 === void 0 ? void 0 : _business8.logoUrl) || null;
  }
};