import { contactModel } from "./..";
import { EditMode } from "./common";
import { Status } from "./model";
export function make(props) {
  return {
    id: props.id,
    createdAt: props.createdAt,
    firstName: props.firstName,
    lastName: props.lastName,
    companyName: props.companyName,
    email: props.email,
    phone: props.phone,
    isSample: props.isSample,
    edit: props.edit,
    vendorId: props.vendorId,
    version: props.version,
    accountantAccess: props.accountantAccess,
    status: props.status,
    hasSignedAgreements: true,
    hasPendingProposals: true
  };
}
export function makeNew(props) {
  return {
    firstName: props.firstName,
    lastName: props.lastName,
    companyName: props.companyName,
    email: props.email,
    phone: props.phone,
    isSample: false
  };
}
export const makeEmpty = () => ({
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  phone: '',
  isSample: false
});
export function makeSampleContact(props) {
  return make({
    id: 'sample_contact_id',
    createdAt: props.createdAt,
    firstName: 'Sample',
    lastName: 'Client',
    companyName: 'Anchor (Sample)',
    email: 'sampleclient@sayanchor.com',
    phone: null,
    isSample: true,
    edit: {
      mode: EditMode.Readonly
    },
    vendorId: props.vendorId || '',
    version: 'sample_contact_version',
    accountantAccess: contactModel.AccountantAccess.NonCandidate,
    status: Status.Active,
    hasSignedAgreements: true,
    hasPendingProposals: false
  });
}