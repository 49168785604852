const _excluded = ["charge"],
  _excluded2 = ["invoice"],
  _excluded3 = ["service"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
import * as serviceModel from "../service";
import compact from 'lodash/compact';
import { EntryType } from "./model";
import { MoneyModel } from "../MoneyModel";
export function fromDTO(dto) {
  return compact(dto.logs.map(entryFromDTO));
}
export function entryFromDTO(dto) {
  switch (dto.type) {
    case EntryType.Relationship:
      return {
        id: dto.id,
        type: EntryType.Relationship,
        timestamp: new Date(dto.timestamp),
        state: dto.state,
        data: dto.data
      };
    case EntryType.Charge:
      {
        const _dto$data = dto.data,
          {
            charge
          } = _dto$data,
          rest = _objectWithoutProperties(_dto$data, _excluded);
        return {
          id: dto.id,
          type: EntryType.Charge,
          timestamp: new Date(dto.timestamp),
          state: dto.state,
          data: _objectSpread(_objectSpread({}, rest), {}, {
            charge: {
              id: charge.id,
              name: charge.name,
              price: MoneyModel.fromDTO(charge.temp_price),
              note: charge.note
            }
          })
        };
      }
    case EntryType.Invoice:
      {
        const _dto$data2 = dto.data,
          {
            invoice
          } = _dto$data2,
          rest = _objectWithoutProperties(_dto$data2, _excluded2);
        return {
          id: dto.id,
          type: EntryType.Invoice,
          timestamp: new Date(dto.timestamp),
          state: dto.state,
          data: _objectSpread(_objectSpread({}, rest), {}, {
            invoice: _objectSpread(_objectSpread({}, invoice), {}, {
              totalAmount: MoneyModel.fromDTO(invoice.totalAmount)
            })
          })
        };
      }
    case EntryType.Service:
      {
        const _dto$data3 = dto.data,
          {
            service
          } = _dto$data3,
          rest = _objectWithoutProperties(_dto$data3, _excluded3);
        return {
          id: dto.id,
          type: EntryType.Service,
          timestamp: new Date(dto.timestamp),
          state: dto.state,
          data: _objectSpread(_objectSpread({}, rest), {}, {
            service: {
              id: service.id,
              name: service.name,
              note: service.note,
              changes: service.changes ? {
                name: service.changes.name,
                description: service.changes.description,
                billing: service.changes.billing && serviceModel.billingFromDTO(service.changes.billing) || null,
                cost: service.changes.cost && serviceModel.costFromDTO(service.changes.cost),
                integratedClass: service.changes.integratedClass
              } : undefined,
              beforeChanges: service.beforeChanges ? {
                name: service.beforeChanges.name,
                description: service.beforeChanges.description,
                billing: service.beforeChanges.billing && serviceModel.billingFromDTO(service.beforeChanges.billing) || null,
                cost: service.beforeChanges.cost && serviceModel.costFromDTO(service.beforeChanges.cost),
                integratedClass: service.beforeChanges.integratedClass
              } : undefined
            }
          })
        };
      }
  }
}