// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jSiAk{width:400px}.LZtRR{display:flex;flex-direction:row;gap:4px}.LZtRR .DbpH1{display:flex;flex-direction:row}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autocompleteWrapper": `jSiAk`,
	"accountToSetColumn": `LZtRR`,
	"textWrapper": `DbpH1`
};
export default ___CSS_LOADER_EXPORT___;
