import { ErrorText } from "../constants";
import { useGlobalContext, useStoreActions } from "../contexts";
import { ServerError } from "../infra/ErrorResponse";
import { handleRequest } from "../infra/utils";
const loaderName = 'updateRelationship';
export function useUpdateRelationship() {
  const {
    api
  } = useStoreActions();
  const {
    startLoader,
    stopLoader,
    showErrorToast,
    showSuccessToast
  } = useGlobalContext();
  return async function updateRelationship(_ref) {
    let {
      id,
      version,
      changes,
      onSuccess
    } = _ref;
    startLoader(loaderName);
    const updateResult = await handleRequest(api.relationship.update(id, {
      version,
      changes
    }));
    if (!updateResult.success) {
      showErrorToast(updateResult.error.hasServerError(ServerError.MismatchingRelationshipVersion) ? ErrorText.MismatchingRelationshipVersion : ErrorText.UnexpectedErrorTryAgain, 'edit-agr-error');
      return stopLoader(loaderName);
    }
    onSuccess && (await onSuccess());
    showSuccessToast('Changes saved successfully', 'edit-agr-success');
    stopLoader(loaderName);
  };
}