function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useGlobalContext, useIoContext, useStoreActions } from "../contexts";
import { handleRequest } from "../infra/utils";
import { routes } from "../constants";
import { useOpenCopyLinkModal } from "./useOpenCopyLinkModal/useOpenCopyLinkModal";
import { ErrorResponse, ServerError } from "../infra/ErrorResponse";
export function useCopyProposalLink() {
  const globalContext = useGlobalContext();
  const {
    api,
    logBI
  } = useStoreActions();
  const {
    openCopyLinkForProposal
  } = useOpenCopyLinkModal();
  const {
    copyToClipboard
  } = useIoContext();
  async function getAgreementNotificationLink(agreementId) {
    try {
      const notificationId = await api.relationship.getSingleUseApprovalLink(agreementId);
      return "https://".concat(window.location.host).concat(routes.notification(notificationId));
    } catch (err) {
      handleGetLinkError(err);
      throw err;
    }
  }
  async function getAgreementNotificationLinks(agreementId) {
    try {
      const response = await api.relationship.getProposalLinks(agreementId);
      return [response.primary, ...(response.additionalRecipients || [])].map(reviewer => _objectSpread(_objectSpread({}, reviewer), {}, {
        notificationId: "https://".concat(window.location.host).concat(routes.notification(reviewer.notificationId))
      }));
    } catch (err) {
      handleGetLinkError(err);
      throw err;
    }
  }
  function handleGetLinkError(err) {
    const e = new ErrorResponse(err);
    const message = e.hasServerError(ServerError.RelationshipAlreadyApproved) ? 'Failed to generate link, the proposal was already approved' : 'Failed to generate link, please try again';
    globalContext.showErrorToast(message, 'agreement-link-error-toast');
  }
  async function copyNotificationUrl(notificationUrl) {
    try {
      await copyToClipboard(notificationUrl);
      globalContext.showSuccessToast('Link copied to clipboard');
    } catch (e) {
      // e.g. fails if the document loses focus.
      globalContext.showErrorToast('Failed to copy link to clipboard');
    }
  }
  async function copyAgreementLink(agreementId, vendorId, customerId, progressStatus) {
    logBI(10589, {
      vendorId,
      clientId: customerId,
      agreementStatus: progressStatus
    });
    const response = await handleRequest(getAgreementNotificationLinks(agreementId));
    if (!response.success) {
      return '';
    }
    const key = 'recipients';
    await openCopyLinkForProposal({
      [key]: response.data,
      testid: 'copy-direct-link-copy'
    });
  }
  return {
    getAgreementNotificationLink,
    getAgreementNotificationLinks,
    copyAgreementLink,
    copyNotificationUrl
  };
}