function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { noHtmlPatternValidator } from 'validators/src/rawValidators';
import { serviceModel, timeModel } from "../../models";
export const makeDraft = data => {
  var _data$clientContactId, _data$isExistingCusto, _data$serviceBundles;
  return {
    id: data.id,
    title: noHtmlPatternValidator(data.title) ? data.title : '',
    clientContactId: (_data$clientContactId = data.clientContactId) !== null && _data$clientContactId !== void 0 ? _data$clientContactId : null,
    isSample: data.isSample,
    lastModifiedOn: Date.now(),
    extraClause: noHtmlPatternValidator(data.extraClause) ? data.extraClause : '',
    requirePaymentMethod: data.requirePaymentMethod,
    allowManualPayments: data.allowManualPayments,
    customNotificationText: data.customNotificationText,
    creationStrategy: null,
    isExistingCustomer: (_data$isExistingCusto = data.isExistingCustomer) !== null && _data$isExistingCusto !== void 0 ? _data$isExistingCusto : false,
    netTerms: data.netTerms,
    effectiveDate: data.effectiveDate ? timeModel.toDatestampObject(data.effectiveDate) : null,
    coverLetter: data.coverLetter,
    feeControls: {
      coverCreditCardFees: data.coverCreditCardFees,
      coverDirectDebitFees: false
    },
    legalTerms: data.legalTerms,
    services: data.services.map(serviceModel.toDTO),
    serviceBundles: (_data$serviceBundles = data.serviceBundles) !== null && _data$serviceBundles !== void 0 && _data$serviceBundles.bundles.length ? {
      bundles: data.serviceBundles.bundles.map(b => _objectSpread(_objectSpread({}, b), {}, {
        services: b.services.map(serviceModel.toDTO)
      }))
    } : undefined,
    amendmentsApprovalMethod: data.amendmentsApprovalMethod,
    invoicesApprovalMethod: data.invoicesApprovalMethod,
    requestAccountantAccess: data.requestAccountantAccess,
    reviewers: data.reviewers,
    introVideo: data.introVideo
  };
};