import { invoiceIntegrationModel, timeModel, payoutIntegrationModel } from "./..";
import Decimal from 'decimal.js';
import { MoneyModel } from "../MoneyModel";
export function fromDTO(payoutDTO) {
  return {
    id: payoutDTO.id,
    createdAt: new Date(payoutDTO.createdAt),
    amount: MoneyModel.fromDTO(payoutDTO.amount),
    status: payoutDTO.status,
    invoices: payoutDTO.invoices.map(fromPayoutInvoiceDTO),
    integration: payoutDTO.integration && payoutIntegrationModel.fromDTO(payoutDTO.integration),
    payoutFailure: payoutDTO.payoutFailure
  };
}
export function fromPayoutInvoiceDTO(dto) {
  const zeroFee = new MoneyModel(new Decimal(0));
  const fee = dto.fee ? MoneyModel.fromDTO(dto.fee) : zeroFee;
  const processingFee = MoneyModel.fromDTO(dto.processingFee);
  const customerProcessingFee = MoneyModel.fromDTO(dto.customerProcessingFee);
  const disputeFee = MoneyModel.fromDTO(dto.disputeFee);
  const totalAmount = MoneyModel.fromDTO(dto.totalAmount);
  const depositAmount = MoneyModel.fromDTO(dto.depositAmount);
  const serviceFeeRefund = MoneyModel.fromDTO(dto.serviceFeeRefund);
  return {
    id: dto.id,
    type: dto.type,
    relationshipId: dto.relationshipId,
    createdOn: timeModel.toDate(dto.createdOn),
    dueDate: timeModel.toDate(dto.dueDate),
    number: dto.number,
    formattedNumber: dto.formattedNumber,
    title: dto.title,
    customer: dto.customer,
    fee,
    processingFee,
    customerProcessingFee,
    disputeFee,
    serviceFeeRefund,
    totalFees: fee.add(processingFee).add(customerProcessingFee).add(disputeFee).add(serviceFeeRefund),
    totalAmount: totalAmount,
    depositAmount: depositAmount,
    integration: dto.integration && invoiceIntegrationModel.fromDTO(dto.integration, totalAmount.isZero())
  };
}