import { activityLogModel } from "./..";
import { ServiceCostType } from "../service/model";
import { EntryType } from "./model";
// entry
export const id = _ref => {
  let {
    id
  } = _ref;
  return id;
};
export const timestamp = _ref2 => {
  let {
    timestamp
  } = _ref2;
  return timestamp;
};
export const type = _ref3 => {
  let {
    type
  } = _ref3;
  return type;
};
export const vendor = _ref4 => {
  let {
    data
  } = _ref4;
  return data === null || data === void 0 ? void 0 : data.vendor;
};
export const vendorName = _ref5 => {
  var _data$vendor;
  let {
    data
  } = _ref5;
  return data === null || data === void 0 || (_data$vendor = data.vendor) === null || _data$vendor === void 0 ? void 0 : _data$vendor.name;
};
export const customer = _ref6 => {
  let {
    data
  } = _ref6;
  return data === null || data === void 0 ? void 0 : data.customer;
};
export const customerName = _ref7 => {
  var _data$customer;
  let {
    data
  } = _ref7;
  return data === null || data === void 0 || (_data$customer = data.customer) === null || _data$customer === void 0 ? void 0 : _data$customer.name;
};
function nameAndEmail(name, email) {
  return name ? email ? "".concat(name, " (").concat(email, ")") : name : '';
}
export const customerNameAndEmail = _ref8 => {
  let {
    data
  } = _ref8;
  return data !== null && data !== void 0 && data.customer ? nameAndEmail(data.customer.name, data.customer.email) : '';
};
export const reviewerNameAndEmail = reviewer => nameAndEmail(reviewer.name, reviewer.email);

// agreement
export const agreementState = _ref9 => {
  let {
    state
  } = _ref9;
  return state;
};
export const agreementChannel = agreementEntry => agreementEntry.data.channel;
export const agreementChangesNetTerms = _ref10 => {
  var _data$changes;
  let {
    data
  } = _ref10;
  return (_data$changes = data.changes) === null || _data$changes === void 0 ? void 0 : _data$changes.netTerms;
};
export const agreementChangesAmendmentsApprovalMethod = _ref11 => {
  var _data$changes2;
  let {
    data
  } = _ref11;
  return (_data$changes2 = data.changes) === null || _data$changes2 === void 0 ? void 0 : _data$changes2.amendmentsApprovalMethod;
};
export const agreementChangesInvoicesApprovalMethod = _ref12 => {
  var _data$changes3;
  let {
    data
  } = _ref12;
  return (_data$changes3 = data.changes) === null || _data$changes3 === void 0 ? void 0 : _data$changes3.invoicesApprovalMethod;
};
export const agreementChangesTitle = _ref13 => {
  var _data$changes4;
  let {
    data
  } = _ref13;
  return (_data$changes4 = data.changes) === null || _data$changes4 === void 0 ? void 0 : _data$changes4.title;
};
export const agreementChangesManualPayments = _ref14 => {
  var _data$changes5;
  let {
    data
  } = _ref14;
  return (_data$changes5 = data.changes) === null || _data$changes5 === void 0 ? void 0 : _data$changes5.manualPayments;
};
export const agreementChangesCoverCCFees = _ref15 => {
  var _data$changes6;
  let {
    data
  } = _ref15;
  return (_data$changes6 = data.changes) === null || _data$changes6 === void 0 ? void 0 : _data$changes6.coverCreditCardFees;
};
export const agreementChangesSeller = _ref16 => {
  var _data$changes7;
  let {
    data
  } = _ref16;
  return (_data$changes7 = data.changes) === null || _data$changes7 === void 0 ? void 0 : _data$changes7.seller;
};
export const agreementPaymentMethod = _ref17 => {
  let {
    data
  } = _ref17;
  return data.paymentMethod;
};
export function isReminderEntry(entry) {
  return type(entry) === EntryType.Relationship && [activityLogModel.AgreementState.SigningReminderSent, activityLogModel.AgreementState.SigningReminderSentManual, activityLogModel.AgreementState.MicroDepositReminderSent].includes(entry.state);
}

// invoice
export const invoiceState = _ref18 => {
  let {
    state
  } = _ref18;
  return state;
};
export const invoiceId = _ref19 => {
  let {
    data
  } = _ref19;
  return data.invoice.id;
};
export const invoiceFormattedNumber = _ref20 => {
  let {
    data
  } = _ref20;
  return data.invoice.formattedNumber;
};
export const invoiceTotalAmount = _ref21 => {
  let {
    data
  } = _ref21;
  return data.invoice.totalAmount;
};

// charge
export const chargeState = _ref22 => {
  let {
    state
  } = _ref22;
  return state;
};
export const chargePrice = _ref23 => {
  let {
    data
  } = _ref23;
  return data.charge.price;
};
export const charge = _ref24 => {
  let {
    data
  } = _ref24;
  return data.charge;
};
export const chargeNote = _ref25 => {
  let {
    data
  } = _ref25;
  return data.charge.note;
};

// service
export const serviceState = _ref26 => {
  let {
    state
  } = _ref26;
  return state;
};
export const service = _ref27 => {
  let {
    data
  } = _ref27;
  return data.service;
};
export const beforeChangesPrice = service => {
  var _service$beforeChange;
  if (!((_service$beforeChange = service.beforeChanges) !== null && _service$beforeChange !== void 0 && (_service$beforeChange = _service$beforeChange.cost) !== null && _service$beforeChange !== void 0 && _service$beforeChange.pricingType)) return null;
  switch (service.beforeChanges.cost.pricingType) {
    case ServiceCostType.Fixed:
    case ServiceCostType.Variable:
      return service.beforeChanges.cost.price;
    case ServiceCostType.Range:
      return service.beforeChanges.cost.maxPrice;
  }
};
export const changesPrice = service => {
  var _service$changes;
  if (!((_service$changes = service.changes) !== null && _service$changes !== void 0 && (_service$changes = _service$changes.cost) !== null && _service$changes !== void 0 && _service$changes.pricingType)) return null;
  switch (service.changes.cost.pricingType) {
    case ServiceCostType.Fixed:
    case ServiceCostType.Variable:
      return service.changes.cost.price;
    case ServiceCostType.Range:
      return service.changes.cost.maxPrice;
  }
};
export function changesIntegratedClassName(service) {
  var _service$changes2;
  return ((_service$changes2 = service.changes) === null || _service$changes2 === void 0 || (_service$changes2 = _service$changes2.integratedClass) === null || _service$changes2 === void 0 ? void 0 : _service$changes2.name) || null;
}
export function beforeChangesIntegratedClassName(service) {
  var _service$beforeChange2;
  return ((_service$beforeChange2 = service.beforeChanges) === null || _service$beforeChange2 === void 0 || (_service$beforeChange2 = _service$beforeChange2.integratedClass) === null || _service$beforeChange2 === void 0 ? void 0 : _service$beforeChange2.name) || null;
}
export function isAgreementEntry(entry) {
  return type(entry) === EntryType.Relationship;
}