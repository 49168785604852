import { approvalMethodNoticePeriodModel, paymentMethodModel, relationshipModel, serviceModel, timeModel } from "../../models";
import { addDays } from 'date-fns';
import { getServiceChangeBullets } from "../../business-logic/amendmentsLogic";
import isEqual from 'lodash/isEqual';
import { useFormContext } from 'react-hook-form';
import { numberAndUnit } from "../../infra/utils";
import compact from 'lodash/compact';
import { makeServiceQboClassChange } from "../../models/agreement-change";
export const useEditAgreementForm = () => useFormContext();
export const getApprovalCalloutText = amendmentsApprovalMethod => {
  const secondSentence = 'Invoices issued before changes take effect will require client approval.';
  return approvalMethodNoticePeriodModel.isAutoApproval(amendmentsApprovalMethod) ? {
    type: 'auto',
    text: "This agreement's terms allow auto-acceptance of changes within ".concat(numberAndUnit(amendmentsApprovalMethod.auto.noticePeriodDays, 'day'), " unless declined. ").concat(secondSentence)
  } : {
    type: 'manual',
    text: "This agreement's terms require manual approval of changes by the client. ".concat(secondSentence)
  };
};
export const getServiceAutoApprovalDate = (service, relationship, now) => {
  if (serviceModel.hasApprovalMethod(service)) {
    const autoApprovalDate = serviceModel.autoApprovalDate(service);
    return autoApprovalDate ? new Date(autoApprovalDate) : null;
  }
  return getAutoApprovalDateFromRelationship(relationship, now);
};
export function willEmailBeSent(services, originalServicesMap, relationship, updatedNetTerms, selectedLegalTermsId, amendmentsApprovalMethod, invoicesApprovalMethod, allowManualPayments, coverCreditCardFees) {
  const isCreditCardSelected = !!relationship.paymentMethod && paymentMethodModel.isCreditCard(relationship.paymentMethod);
  const coverCCFeesWillSendEmail = coverCreditCardFees !== relationshipModel.isVendorCoveringCCFee(relationship) && coverCreditCardFees !== relationshipModel.updatedIsVendorCoveringCCFeeValue(relationship) && isCreditCardSelected;
  const allowManualPaymentsWillSendEmail = allowManualPayments !== relationship.allowManualPayments && allowManualPayments !== relationshipModel.updatedAllowManualPaymentsValue(relationship);
  return relationship.legalTerms.id !== selectedLegalTermsId || relationshipModel.actualNetTerms(relationship) !== updatedNetTerms || !approvalMethodNoticePeriodModel.isEqual(relationshipModel.actualAmendmentsApprovalMethod(relationship), amendmentsApprovalMethod) || !approvalMethodNoticePeriodModel.isEqual(relationshipModel.actualInvoicesApprovalMethod(relationship), invoicesApprovalMethod) || allowManualPaymentsWillSendEmail || coverCCFeesWillSendEmail || services.some(s => {
    const originalService = originalServicesMap.get(s.id);
    const isNewlyTerminated = serviceModel.isTerminated(s.status) && !!originalService && !serviceModel.isTerminated(originalService.status);
    const isExistingNewService = serviceModel.isAwaitingApproval(s.status) && !!originalService && serviceModel.isAwaitingApproval(originalService.status);
    return !isExistingNewService && (isNewlyTerminated || !isEqual(originalService, s) && getServiceChangeBullets(s).some(b => !b.emailWontBeSent));
  });
}
function getAutoApprovalDateFromRelationship(relationship, now) {
  return approvalMethodNoticePeriodModel.isAutoApproval(relationship.amendmentsApprovalMethod) ? addDays(now, relationship.amendmentsApprovalMethod.auto.noticePeriodDays) : null;
}
export function getAmendedNextBillingDateText(service, amendedNextBillingDate) {
  const formatted = timeModel.formatDate(amendedNextBillingDate);
  const pausedText = serviceModel.isPaused(service) ? " To avoid this resume billing after this date." : '';
  return "The next invoice for this service will be issued on ".concat(formatted, ". As the service has already been billed for this period, client approval will be required for the invoice.").concat(pausedText);
}
export function validateService(service) {
  return !serviceModel.isInvalid(serviceModel.applyChanges(service));
}
export function changeServicePriceAndDiscount(service, cost) {
  const {
    isAwaitingApproval,
    setPrice,
    setPriceChanges,
    setDiscount,
    setDiscountChanges
  } = serviceModel;
  return isAwaitingApproval(service.status) ? setPrice(setDiscount(service, cost.discount), cost) : setPriceChanges(setDiscountChanges(service, cost.discount), cost);
}
export function makeServiceQboClassChanges(services, originalServices, qboClasses) {
  const qboClassesMap = new Map(qboClasses === null || qboClasses === void 0 ? void 0 : qboClasses.map(c => [c.id, c]));
  const originalServicesMap = new Map(originalServices.map(s => [s.id, s]));
  const getQboClassEssentialData = id => {
    const qboClass = id ? qboClassesMap.get(id) : null;
    return qboClass ? {
      id: qboClass.id,
      name: qboClass.name
    } : null;
  };
  return compact(services.map(s => {
    const originalService = originalServicesMap.get(s.id);
    const newId = serviceModel.integratedClassId(s);
    const oldId = originalService ? serviceModel.integratedClassId(originalService) : null;
    if (newId === oldId) {
      return null;
    }
    return makeServiceQboClassChange(s.id, getQboClassEssentialData(oldId), getQboClassEssentialData(newId));
  }));
}