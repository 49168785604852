import lodashGet from 'lodash/get';
import { makeNoVideos } from "../types/api/video.model";
import colors from 'seagull/foundations/colors.module.scss';
import branding from "../infra/branding.module.scss";
import { generateUniqueId } from 'seagull/utils/generateUniqueId';

/** The location in featureFlags where we store the global feature-flags for users without a businessId. */
export const defaultFeatureFlagsKey = 'default';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeDefaultLoadableContent = () => ({
  loading: false,
  data: null,
  error: null,
  lastSuccessfulFetch: null
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeLoadableContentSuccess = (data, lastSuccessfulFetch) => ({
  loading: false,
  data,
  error: null,
  lastSuccessfulFetch: lastSuccessfulFetch !== null && lastSuccessfulFetch !== void 0 ? lastSuccessfulFetch : Date.now()
});
export function getDefaultSessionData() {
  return {
    mgmtHost: '',
    apiGatewayHost: '',
    apiHost: '',
    pdfHost: '',
    authDomain: '',
    useAuthorizationHeader: false,
    sessionTimeoutMS: 36000000
  };
}
export function getDefaultInitialState(session) {
  let additionalInfo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    authFlowDone = false,
    featureFlags = {}
  } = additionalInfo;
  return {
    session,
    ff: {
      featureFlags
    },
    relationshipScope: null,
    authToken: null,
    authFlowDone,
    claims: null,
    userInfo: null,
    videos: makeNoVideos(),
    userClientData: null,
    businessClientData: {},
    stripeLoadingFailed: false,
    inAppNotificationsDrawerOpen: false,
    activityLog: {},
    agreements: makeDefaultLoadableContent(),
    proposals: makeDefaultLoadableContent(),
    proposalTemplates: makeDefaultLoadableContent(),
    drafts: makeDefaultLoadableContent(),
    payouts: makeDefaultLoadableContent(),
    invoices: makeDefaultLoadableContent(),
    invoicesCounters: makeDefaultLoadableContent(),
    contacts: makeDefaultLoadableContent(),
    qboCustomers: makeDefaultLoadableContent(),
    qboItems: makeDefaultLoadableContent(),
    qboClasses: makeDefaultLoadableContent(),
    financialCents: {
      items: makeDefaultLoadableContent()
    },
    integrations: makeDefaultLoadableContent(),
    inAppNotifications: makeDefaultLoadableContent(),
    showNotificationPimple: false,
    sidebarCounters: {
      agreements: 0,
      drafts: 0,
      invoices: 0,
      contacts: 0
    },
    monthlyEarnings: makeDefaultLoadableContent(),
    serviceTemplates: makeDefaultLoadableContent(),
    legalTerms: makeDefaultLoadableContent(),
    appConfig: {
      termsAndConditionsPDFSizeLimitInMegabytes: 50,
      qboPollingInterval: 5000,
      generateUniqueId
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOrCreateStoreLocation = (state, baseLocation) => {
  const asArray = Array.isArray(baseLocation) ? baseLocation : [baseLocation];
  const location = lodashGet(state, asArray);
  if (location) {
    return location;
  }

  // Create empty state if there is no location.
  const pathWithoutLastPart = asArray.slice(0, -1);
  const lastPathPart = asArray[asArray.length - 1];
  const parent = lodashGet(state, pathWithoutLastPart);
  parent[lastPathPart] = makeDefaultLoadableContent();
  return parent[lastPathPart];
};
export function makeDefaultBranding() {
  return {
    textStandard: colors.defaultTextStandard,
    textSecondary: colors.defaultTextSecondary,
    buttonPrimary: colors.defaultButtonPrimary,
    buttonSecondary: colors.defaultButtonSecondary,
    buttonDisabled: colors.defaultButtonDisabled,
    backgroundGradientStart: branding.defaultBgGradientColor1,
    backgroundGradientEnd: branding.defaultBgGradientColor2,
    backgroundStandard: colors.defaultBgStandard,
    backgroundLight: colors.defaultBgLight,
    progressBarComplete: colors.defaultStepperCompleted,
    progressBarIncomplete: colors.defaultStepperIncomplete
  };
}