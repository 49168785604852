import { useRestfulWrapper } from "../../hooks/useRestfulWrapper";

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export default function RestfulWrapper(props) {
  const {
    initialData,
    fetch,
    renderChild,
    renderError,
    getStoreLocation,
    renderLoader
  } = props;
  const {
    renderRestfulState
  } = useRestfulWrapper({
    initialData,
    getStoreLocation,
    fetch
  });
  return renderRestfulState({
    renderChild,
    renderError,
    renderLoader
  });
}