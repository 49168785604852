import { NotificationSettingsEntryType } from "./model";
export function makeSingleNotificationSettingsEntryUpdate(recipientTypes) {
  return {
    type: NotificationSettingsEntryType.Single,
    recipientTypes
  };
}
export const makeRecurringNotificationSettingsEntryUpdate = (recipientTypes, recurring) => ({
  type: NotificationSettingsEntryType.Recurring,
  recipientTypes,
  recurring
});