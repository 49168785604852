import Decimal from 'decimal.js';
import { fromDTO as availableCreditFromDTO } from "./available-credit";
import { fromResponseDTO } from "./manual-service-billing";
export class BillingControlDataModel {
  static fromDTO(dto) {
    const credits = dto.credits.map(availableCreditFromDTO);
    return new BillingControlDataModel(fromResponseDTO(dto.manualServices), credits, sumCredits(credits));
  }
  static make() {
    let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const credits = data.credits || [];
    return new BillingControlDataModel(data.manualServices || {}, credits, sumCredits(credits));
  }
  constructor(manualServices, credits, totalCredits) {
    this.manualServices = manualServices;
    this.credits = credits;
    this.totalCredits = totalCredits;
  }
}
function sumCredits(credits) {
  return credits.filter(c => !c.isPaused).reduce((a, b) => a.plus(b.totalAmount), new Decimal(0)).abs();
}